<template>
  <v-dialog v-model="dialog" width="1000" :retain-focus="false">
    <!--Button and dialog ro show the table-->
    <template v-slot:activator="{ on, attrs }">
      <v-card
        class="mx-auto mt-0"
        :outlined="outlined"
        :elevation="launchcardelevation"
        id="tableLauncher"
        :color="launchcardcolor"
        v-if="type != 'cashClose'"
      >
        <v-row
          justify="end"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'position: absolute; top: 12px; right: 25px'
              : 'position: absolute; top: 20px; right: 36px'
          "
          v-if="type == 'total'"
        >
          <v-btn
            class="mr-1"
            fab
            style="height: 30px; width: 30px"
            elevation="0"
            :outlined="tab !== 'total'"
            @click="tab = 'total'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" size="20"
                  >mdi-currency-eur</v-icon
                >
              </template>
              <span>Total</span>
            </v-tooltip>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                value="list"
                fab
                style="height: 30px; width: 30px"
                elevation="0"
                :outlined="tab !== 'grafic'"
                @click="tab = 'grafic'"
                v-on="on"
              >
                <v-icon v-bind="attrs" size="20">$grafic</v-icon>
              </v-btn>
            </template>
            <span>Gráfico</span>
          </v-tooltip>

          <v-btn
            style="margin-top: -5px"
            v-if="tab != 'grafic'"
            @click="
              $store.state.expenses.hide.total
                ? ($store.state.expenses.hide.total = false)
                : ($store.state.expenses.hide.total = true)
            "
            icon
          >
            <v-icon size="30">$hide</v-icon></v-btn
          >
        </v-row>
        <v-row
          justify="end"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'position: absolute; top: 12px; right: 25px'
              : 'position: absolute; top: 40px; right: 36px'
          "
          v-else-if="type == 'tattooer'"
        >
          <v-btn
            class="mr-1"
            fab
            style="height: 30px; width: 30px"
            elevation="0"
            :outlined="comision !== false"
            @click="
              comision = false;
              fetchAmount();
              fetchExpenses();
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" size="20"
                  >mdi-currency-eur</v-icon
                >
              </template>
              <span>Suma Total</span>
            </v-tooltip>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                value="list"
                fab
                style="height: 30px; width: 30px"
                elevation="0"
                :outlined="comision !== true"
                @click="
                  comision = true;
                  fetchAmount();
                  fetchExpenses();
                "
                v-on="on"
              >
                <v-icon v-bind="attrs" size="20">mdi-percent</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("expenses.pendings") }}</span>
          </v-tooltip>

          <v-btn
            style="margin-top: -5px"
            v-if="tab != 'grafic'"
            @click="
              $store.state.expenses.hide.total
                ? ($store.state.expenses.hide.total = false)
                : ($store.state.expenses.hide.total = true)
            "
            icon
          >
            <v-icon size="30">$hide</v-icon></v-btn
          >
        </v-row>
        <v-row
          justify="end"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'position: absolute; top: 12px; right: 25px'
              : 'position: absolute; top: 20px; right: 36px'
          "
          v-else-if="
            type != 'dashboard-tattooer-small-ingresos-totales' &&
              type != 'dashboard-tattooer-small-ingresos-pendientes'
          "
        >
          <v-btn style="margin-top: -5px" icon @click="hideButton()">
            <v-icon size="30">$hide</v-icon></v-btn
          >
        </v-row>
        <v-card-title class="py-2">
          <v-row
            id="title"
            :style="$vuetify.breakpoint.smAndUp ? `height:${height}` : ''"
            :align="type != 'appointment' ? 'center' : null"
            v-if="type != 'total'"
          >
            <v-col
              cols="12"
              :class="type != 'appointment' ? 'pt-md-0' : 'pt-md-2'"
            >
              <div
                v-if="size === 'small'"
                id="titulo"
                class="form-label"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); 
                 line-height: normal; display: inline-block; 
                 text-align: center; word-break: break-word;`
                    : `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); 
                 line-height: normal; display: inline-block; 
                 text-align: center; word-break: break-word;word-spacing:213px`
                "
              >
                <u v-if="underline">{{ title }}</u>
                <span v-else>{{ title }}</span>
              </div>
              <div
                v-else-if="$vuetify.breakpoint.xsOnly"
                id="titulo"
                class="form-label"
                :style="
                  `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); text-transform: uppercase;
                 line-height: normal; display: inline-block; 
                 text-align: center; word-break: break-word;`
                "
              >
                <u v-if="underline">{{ title }}</u>
                <span v-else>{{ title }}</span>
              </div>
              <div
                v-else
                id="titulo"
                :class="
                  type != 'appointment'
                    ? 'form-label pl-5 mt-n7'
                    : 'form-label pl-5 mt-3'
                "
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); text-transform: uppercase;
                 line-height: normal; 
                 padding-left:0px !important; word-break: break-word;`
                    : `font-size:${fontsizeTitle}; text-transform: uppercase`
                "
              >
                {{ comision ? $t("expenses.pendings") : title }}
              </div>
            </v-col>
          </v-row>
          <v-row
            id="title"
            :style="`height:${height}`"
            :align="type != 'appointment' ? 'center' : null"
            v-else-if="type == 'total' && tab == 'total'"
          >
            <v-col
              cols="12"
              :class="type != 'appointment' ? 'pt-md-0' : 'pt-md-2'"
              ><div
                v-if="size === 'small'"
                id="titulo"
                class="form-label"
                :style="
                  `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); 
                 line-height: normal; display: inline-block; 
                 text-align: center; word-break: break-word;`
                "
              >
                <u v-if="underline">{{ title }}</u>
                <span v-else>{{ title }}</span>
              </div>
              <div
                v-else-if="$vuetify.breakpoint.xsOnly"
                id="titulo"
                class="form-label"
                :style="
                  `font-size:${fontsizeTitle};text-transform: uppercase; color: var(--v-primary-base); line-height: normal; display: inline-block;  text-align: center; word-break: break-word;`
                "
              >
                <u v-if="underline">{{ title }}</u>
                <span v-else>{{ title }}</span>
              </div>
              <div
                v-else
                id="titulo"
                class="form-label pl-5 mt-n7"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? `font-size:${fontsizeTitle};
                 color: var(--v-primary-base); text-transform: uppercase;
                 line-height: normal; 
                 padding-left:0px !important; word-break: break-word;`
                    : `font-size:${fontsizeTitle}; text-transform: uppercase`
                "
              >
                {{ title }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="type != 'total'">
          <v-row :style="`height:${height}`" align="center">
            <v-col cols="12">
              <h1
                id="number"
                :class="
                  type != 'dashboard-tattooer-small-ingresos-totales' &&
                  type != 'dashboard-tattooer-small-ingresos-pendientes'
                    ? 'pt-0 '
                    : 'pt-1'
                "
                v-if="$store.state.expenses.hide[type]"
                class="pt-md-0"
                :style="
                  type != 'appointment'
                    ? $vuetify.breakpoint.xsOnly
                      ? `font-size:${fontsize}; color: white !important;margin-top: -30px; position:relative`
                      : `font-size:${fontsize}; color: white !important; margin-top: -20px`
                    : $vuetify.breakpoint.xsOnly
                    ? `font-size:${fontsize}; color: white !important;margin-top: -30px; position:relative`
                    : 'font-size: 50px; color: white !important; margin-top: -20px'
                "
              >
                <span
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'position: absolute;inset: 40px'
                      : ''
                  "
                  >{{ $n(total, "currency_no_icon") }}€</span
                >
              </h1>
              <h1
                v-else-if="
                  type != 'dashboard-tattooer-small-ingresos-totales' &&
                    type != 'dashboard-tattooer-small-ingresos-pendientes'
                "
                style="position: relative; margin: 0 auto; height: 0px"
              >
                <v-btn
                  :style="$vuetify.breakpoint.xsOnly ? '' : 'margin-top: -40px'"
                  outlined
                  @click="hideButton()"
                >
                  {{ $t("showDetails") }}</v-btn
                >
              </h1>
              <h1
                v-else
                id="number"
                :class="
                  type != 'dashboard-tattooer-small-ingresos-totales' &&
                  type != 'dashboard-tattooer-small-ingresos-pendientes'
                    ? 'pt-5'
                    : 'pt-1'
                "
                class="pt-md-0"
                :style="
                  type != 'appointment'
                    ? `font-size:${fontsize}; color: white !important`
                    : 'font-size: 50px; color: white !important '
                "
              >
                {{ $n(total) }}€
              </h1>
            </v-col>
            <v-col cols="12" class="pa-0 pa-md-3">
              <v-card-actions>
                <v-btn
                  text
                  small
                  left
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    size === 'small'
                      ? $router.name === 'appointmentView'
                        ? 'position: absolute; bottom: 0; margin-left: -7%'
                        : 'position: absolute; bottom: 0; left: 0; right: 0; width: 100%'
                      : 'margin-bottom: -25px'
                  "
                >
                  <span
                    v-if="
                      type != 'dashboard-tattooer-small-ingresos-totales' &&
                        type != 'dashboard-tattooer-small-ingresos-pendientes'
                    "
                  >
                    {{ $t("see_more") }}
                  </span>
                  <span v-else>
                    {{ $t("showDetails") }}
                  </span>
                </v-btn>
                <v-spacer />
                <expenses-form
                  ref="form"
                  :showAddButton="fromButton"
                  :expense="expense"
                  :action="action"
                  :appointmentId="appointment.id || null"
                  :tattooerId="appointment.tattooer_id || null"
                  @update="$emit(`update`)"
                  @actionsave="actionSave"
                  @refreshTable="fetchExpenses"
                  @to_debit="$emit('to_debit')"
                  :type="type"
                  style="margin-bottom: -25px"
                  @open_price="$emit('open_price')"
                />
                <v-spacer v-if="size === 'small'" />
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="type == 'total' && tab == 'total'"
          style="background-color: #1e1e1e"
        >
          <v-row :style="`height:${height}`" align="center">
            <v-col cols="12">
              <h1
                id="number"
                :class="
                  type != 'dashboard-tattooer-small-ingresos-totales' &&
                  type != 'dashboard-tattooer-small-ingresos-pendientes'
                    ? 'pt-0'
                    : 'pt-1'
                "
                v-if="$store.state.expenses.hide.total == true"
                class="pt-md-0"
                :style="
                  `font-size:${fontsize}; color: white !important; margin-top: -20px;position:relative`
                "
              >
                <span
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'position: absolute;inset: 30px'
                      : ''
                  "
                  >{{ $n(total) }}€</span
                >
              </h1>
              <h1
                style="position: relative; margin: 0 auto; height: 0px"
                v-else
              >
                <v-btn
                  :style="$vuetify.breakpoint.xsOnly ? '' : 'margin-top: -40px'"
                  outlined
                  @click="$store.state.expenses.hide.total = true"
                >
                  {{ $t("showDetails") }}</v-btn
                >
              </h1>
            </v-col>
            <v-col cols="12" class="pa-0 pa-md-3">
              <v-card-actions>
                <v-btn
                  text
                  small
                  left
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    size === 'small'
                      ? $router.name === 'appointmentView'
                        ? 'position: absolute; bottom: 0; margin-left: -7%'
                        : 'position: absolute; bottom: 0; left: 0; right: 0; width: 100%'
                      : 'margin-bottom: -25px'
                  "
                >
                  {{ $t("see_more") }}
                </v-btn>
                <v-spacer />
                <expenses-form
                  ref="form"
                  style="margin-bottom: -25px"
                  :showAddButton="fromButton"
                  :expense="expense"
                  :action="action"
                  :appointmentId="appointment.id || null"
                  :tattooerId="appointment.tattooer_id || null"
                  @update="$emit(`update`)"
                  @actionsave="actionSave"
                  @refreshTable="fetchExpenses"
                  :type="type"
                />
                <v-spacer v-if="size === 'small'" />
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="type == 'total' && tab == 'grafic'"
          style="background-color: #1e1e1e"
        >
          <expenses-stats
            ref="statsTable"
            type="gastos"
            @update="updateTables"
          ></expenses-stats>
        </v-card-text>
      </v-card>
    </template>

    <v-card id="card" :absolute="true" :style="`overflow-y: auto;`">
      <v-card-title class="headline" style="text-transform: uppercase">
        {{ comision ? $t("expenses.pendings") : title }}
      </v-card-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="$vuetify.breakpoint.xsOnly && type !== 'appointment'"
            icon
            color="primary"
            style="position: absolute; left: 0%; top: 19px"
            @click="getExcel()"
            v-on="on"
          >
            <v-icon small>$download</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("downloadExcel") }}
        </span>
      </v-tooltip>
      <v-btn
        icon
        elevation="0"
        style="z-index: 10; position: absolute; right: 18px; top: 19px"
        @click="openForm()"
        v-if="
          fromButton && $vuetify.breakpoint.xsOnly && type !== 'appointment'
        "
      >
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            v-if="$vuetify.breakpoint.xsOnly && type === 'appointment'"
          >
            <v-row>
              <v-col cols="6" align="left" class="py-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" @click="getExcel()" v-on="on">
                      <v-icon>$download</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("downloadExcel") }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="6" align="right" class="py-0">
                <v-btn icon elevation="0" @click="openForm()">
                  <v-icon large>mdi-plus-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="
              type !== 'appointment' &&
                type !== 'tattooer' &&
                $store.getters['auth/isStudio']
            "
            cols="12"
            sm="3"
            md="2"
            class="py-0"
          >
            <v-select
              clearable
              v-model="tattooer_id"
              outlined
              dense
              hide-details
              class="icon"
              single-line
              :items="tattooers"
              :label="$tc('artist')"
              item-value="id"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="6"
            sm="3"
            :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
            class="py-0"
          >
            <v-menu
              v-model="menu.first_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  dense
                  :value="from ? $d(new Date(from), 'date2digits') : ''"
                  hide-details
                  readonly
                  v-bind:class="{ 'error--text': from > to }"
                  v-on="on"
                  clearable
                  class="pointer"
                  :label="$tc('from')"
                  @click:clear="from = null"
                >
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="from"
                color="primary"
                no-title
                @input="menu.first_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="6"
            md="3"
            :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
            class="py-0"
          >
            <v-menu
              v-model="menu.last_day"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  dense
                  :value="to ? $d(new Date(to), 'date2digits') : ''"
                  hide-details
                  readonly
                  v-bind:class="{ 'error--text': from > to }"
                  v-on="on"
                  :label="$tc('to')"
                  class="pointer"
                  clearable
                  @click:clear="to = null"
                >
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="to"
                color="primary"
                no-title
                :min="from"
                @input="menu.last_day = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            v-if="type !== 'appointment'"
            sm="3"
            md="2"
            class="py-0"
          >
            <v-menu v-model="others">
              <template v-slot:activator="{ on }">
                <label
                  style="
                    position: absolute;

                    font-size: 10px !important;
                  "
                  v-bind:style="[
                    {
                      top: $vuetify.breakpoint.xsOnly ? '115px' : '70px',
                    },
                  ]"
                >
                  {{ $t(other_filters_applied ? "applyed" : "") }}
                </label>
                <v-btn
                  :outlined="!other_filters_applied"
                  block
                  elevation="0"
                  style="height: 30px; margin-top: 5px; width: 100%"
                  dense
                  v-on="on"
                >
                  {{ $t("other_filters") }}</v-btn
                >
              </template>
              <v-list
                style="max-width: 260px"
                id="others_expenses"
                class="pl-2"
              >
                <v-list-item class="mt-n2">
                  <v-select
                    clearable
                    v-model="filter_type"
                    @input="others = false"
                    dense
                    hide-details
                    class="icon filters no-line name mt-0"
                    single-line
                    @click.stop
                    :items="comes"
                    :label="$tc('economys.come')"
                  >
                  </v-select>
                </v-list-item>
                <v-list-item>
                  <v-select
                    clearable
                    v-model="pay_mode"
                    @input="others = false"
                    dense
                    style="min-width: 150px !important"
                    hide-details
                    class="icon filters no-line name"
                    single-line
                    @click.stop
                    :items="pay"
                    :label="$tc('expenses.pay_mode')"
                  >
                  </v-select>
                </v-list-item>
                <v-list-item>
                  <v-select
                    clearable
                    v-model="recurrent"
                    @input="others = false"
                    dense
                    style="min-width: 150px !important"
                    hide-details
                    class="icon filters no-line name"
                    single-line
                    @click.stop
                    :items="recurrent_list"
                    :label="$tc('expenses.recurrente_ask')"
                  >
                  </v-select>
                </v-list-item> </v-list
            ></v-menu>
          </v-col>
          <v-col cols="12" md="2" class="py-0"> </v-col>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="!$vuetify.breakpoint.xsOnly"
                icon
                color="primary"
                @click="getExcel()"
                v-on="on"
              >
                <v-icon>$download</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("downloadExcel") }}
            </span>
          </v-tooltip>

          <v-btn
            icon
            elevation="0"
            style="z-index: 10"
            @click="openForm()"
            v-if="fromButton && !$vuetify.breakpoint.xsOnly"
          >
            <v-icon large>mdi-plus-circle</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
      <v-data-table
        :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :items="items"
        id="tableExpense"
        :item-class="tableClass"
        :server-items-length="totalExpenses"
        :options.sync="options"
        :style="`max-height:${tableheight}; overflow-y: auto;`"
      >
        <template v-slot:item.conceptos="{ item }">
          <a
            v-if="item.appointment_id && type !== 'appointment'"
            @click="
              $router.push({
                name: 'appointmentView',
                params: { id: item.appointment_id },
              })
            "
          >
            {{ item.conceptos }}
          </a>
          <span v-else>
            {{ item.conceptos }}
          </span>
          <v-chip small color="primary" v-if="item.refunded">
            {{ $t("expenses.refunded") }}</v-chip
          >
        </template>
        <template v-slot:item.cantidad="{ item }">
          <span
            v-bind:class="[
              {
                'error--text':
                  ((comision && item.commission < 0) ||
                    (comision && item.money < 0)) &&
                  (type === 'total' ||
                    type === 'forecast' ||
                    type === 'appointment' ||
                    type === 'customer' ||
                    type === 'tattooer'),
              },
              {
                'success--text':
                  ((comision && item.commission > 0) ||
                    (comision && item.money > 0)) &&
                  (type === 'total' ||
                    type === 'forecast' ||
                    type === 'appointment' ||
                    type === 'customer' ||
                    type === 'tattooer'),
              },
            ]"
          >
            {{
              $n(
                comision
                  ? item.commission_expense
                    ? item.commission_expense.money
                    : item.commission
                  : item.money,
                "currency"
              )
            }}
          </span>
        </template>

        <template v-slot:item.data="{ item }"
          ><span
            v-bind:class="{
              'error--text': type == 'forecast' && today > item.data,
            }"
            >{{ $d(new Date(item.data), "dateShort") }}</span
          ></template
        >

        <template v-slot:item.pending="{ item }">
          <span v-if="item.pending == false" class="success--text">
            {{ $t("expenses.done") }}
          </span>
          <span v-else class="login_error--text">
            {{ $t("expenses.no_done") }}
          </span>
        </template>

        <template v-slot:item.recurrente="{ item }">
          <span v-if="item.recurrente == true">{{ $t("yes") }}</span>
          <span v-else>{{ $t("no") }}</span>
        </template>
        <template v-slot:item.pay_mode="{ item }">
          <span>{{ $t("expenses." + item.pay_mode) }}</span>
        </template>
        <template v-slot:item.tattooer="{ item }">
          <span v-if="item.tattooer">
            {{ item.tattooer.user.first_name }}
            {{ item.tattooer.user.last_name }}
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" large>mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <ExpensesInforme :expense="item" @update="fetchExpenses" />

              <PaymentLink
                :expense="item"
                v-if="
                  item.pending &&
                    $store.getters['auth/getSetting']('pm_stripe_id') !== 0 &&
                    item.money > 0
                "
              />
              <v-list-item
                class="lessMargin"
                v-if="
                  item.pending == true &&
                    item.stripe_token === null &&
                    item.extra !== 1
                "
                @click="confirm(item)"
              >
                <v-list-item-icon style="margin-top: 10px">
                  <v-icon small>mdi-check-underline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{ $t("expenses.p_cobrar") }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="lessMargin"
                v-if="item.commission_payed == false && item.commission"
                @click="confirmCommission(item)"
              >
                <v-list-item-icon style="margin-top: 10px">
                  <v-icon small>mdi-check-underline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{
                    $t("economys.forecast.liquidate_commission")
                  }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="lessMargin"
                v-if="item.editable && item.stripe_token === null"
                @click="edit(item)"
              >
                <v-list-item-icon style="margin-top: 10px">
                  <v-icon small>$edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{ $t("edit", { name: "" }) }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="lessMargin"
                v-else
                @click="$emit('open_price')"
              >
                <v-list-item-icon style="margin-top: 10px">
                  <v-icon small>$edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{ $t("edit", { name: "" }) }}</span>
                </v-list-item-title>
              </v-list-item>
              <ExpensesRefund
                v-if="item.stripe_token && !item.refunded && !item.pending"
                :expense="item"
                @update="fetchExpenses"
              />
              <v-list-item
                class="lessMargin"
                v-if="item.editable && item.stripe_token === null"
                @click="del(item)"
              >
                <v-list-item-icon style="margin-top: 10px">
                  <v-icon small>$delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{ $t("expenses.delete_income") }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          elevation="0"
          outlined
          small
          color="primary"
          @click="dialog = false"
          >{{ $t("hiddenDetails") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!--<v-card
    v-else-if="!dialogTypeCard"
    class="mx-auto mt-0"
    :outlined="outlined"
    elevation="6"
    id="table"
  >
    <v-card-title>
      <div id="titulo" class="form-label">{{ title }}</div>
    </v-card-title>
    <v-card-text :style="`height:${height}; overflow-y: auto;`">
      <v-data-table :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :items="items"
        :footer-props="{
          'rows-per-page-text': '',
          'items-per-page-options': [],
        }"
        :items-per-page="3"
        :server-items-length="totalExpenses"
        :options.sync="options"
      >
        <template v-slot:item.cantidad="{ item }">{{
          $n(item.cantidad, "currency")
        }}</template>

        <template v-slot:item.data="{ item }">{{
          $d(new Date(item.data), "dateShort")
        }}</template>

        <template v-slot:item.pending="{ item }">
          <v-icon v-if="item.pending == true">mdi-check</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </template>

        <template v-slot:item.recurrente="{ item }">
          <v-icon v-if="item.recurrente == true">mdi-check</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="item.pending == true"
                @click="confirm(item.id)"
                >mdi-check-underline</v-icon
              >
            </template>
            <span>Liquidar</span>
          </v-tooltip>

          <!--Icono de edicion con su margen según otros iconos--
  <v-icon v-if="item.pending == true" class="ml-2" @click="edit(item)"
    >$edit</v-icon
  >
  <v-icon v-else class="ml-7" @click="edit(item)">$edit</v-icon>
</template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-btn text small color="primary" @click="overlay = true"
        >Ocultar detalles</v-btn
      >
      <v-spacer />

      <!--v-if="this.type!='forecast' || (this.type == 'forecast' && this.action=='edit')"--

      <expenses-form
        ref="form"
        :showAddButton="fromButton"
        :expense="expense"
        :action="action"
        :appointmentId="appointment.id || null"
        @update="$emit(`update`)"
        @actionsave="actionSave"
        @refreshTable="fetchExpenses"
        :type="type"
      />
    </v-card-actions>

    <v-overlay :absolute="true" :opacity="1" :value="overlay">
      <div id="titulo" class="form-label" style="font-size: 20px">
        {{ title }}
      </div>
      <h1 :style="`font-size:${fontsize}`">{{ $n(total, "currency") }}</h1>
      <v-btn text small color="primary" @click="overlay = false"
        >Mostrar detalles</v-btn
      >
    </v-overlay>
  </v-card>-->
</template>
<script>
import { mapActions, mapState } from "vuex";
import excelGenerator from "@/mixins/excelGenerator";
import * as moment from "moment";
export default {
  name: "ExpensesTable",
  mixins: [excelGenerator],
  props: {
    type: {},
    title: {},
    appointment: {
      default: () => ({}),
    },
    customer: {
      default: () => ({}),
    },
    tattooer: {
      default: () => ({}),
    },
    size: {
      default: "big",
    },
    height: {
      default: "200px",
    },
    outlined: {},
    underline: { default: false },
  },
  components: {
    ExpensesForm: () => import("@/components/expense/Form"),
    ExpensesRefund: () => import("@/components/expense/Refund"),
    ExpensesInforme: () => import("@/components/expense/Informe"),
    ExpensesStats: () => import("@/components/expense/ExpensesStats"),
    PaymentLink: () => import("@/components/expense/PaymentLink"),
  },

  nombreDeTabla: "Gastos",
  watch: {
    options: {
      handler() {
        this.fetchExpenses();
        this.fetchAmount();
      },
      deep: true,
    },
    filtersState: {
      handler() {
        this.fetchExpenses();
        this.fetchAmount();
      },
      deep: true,
    },
  },
  data() {
    return {
      tab: "total",
      comision: false,
      today: null,
      others: false,
      items: [],
      comes: [
        { text: this.$t("economys.comes.all"), value: "all" },
        { text: this.$t("economys.comes.inventory"), value: "inventory" },
        { text: this.$t("economys.comes.appointment"), value: "appointment" },
        { text: this.$t("economys.comes.boxes"), value: "boxes" },
        // { text: this.$t("economys.comes.sell"), value: "sell" },
      ],

      recurrent_list: [
        {
          text: this.$t("yes"),
          value: true,
        },
        {
          text: this.$t("no"),
          value: false,
        },
      ],

      totalExpenses: 0,
      options: {},
      overlay: true,
      total: 0,
      action: "save",
      expense: {
        recurrente: false,
        comision: 11,
        gasto: null,
        tattooer_id: null,
      },
      showAddButton: true,
      dialog: false,
      dialog_refund: false,
      tattooers: [],
      refund: {
        type: null,
      },
      menu: {
        first_day: false,
        last_day: false,
      },
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    if (this.$route.name !== "Dashbord") {
      this.$store.commit("expenses/RESET_EXPENSES_FILTERS");
    }
    if (
      this.type !== "appointment" &&
      this.type !== "customer" &&
      this.type !== "tattooer"
    ) {
      console.log("load in mount");
      this.fetchExpenses();
      this.fetchAmount();
      this.fetchActiveTattooer();
    }
  },
  methods: {
    tableClass(a) {
      switch (this.type) {
        case "appointment":
          if (a.pending) return "appoinment_penging";
      }
    },
    ...mapActions("studios", ["getActiveTattooer"]),
    fetchActiveTattooer() {
      this.getActiveTattooer().then(
        (data) => (this.tattooers = data.map((x) => x.tattooer))
      );
    },
    ...mapActions("expenses", [
      "getExpenses",
      "delExpense",
      "getTotalAmount",
      "confirmExpense",
      "getAll",
      "confirmExpenseCommision",
    ]),
    fetchExpenses() {
      this.getExpenses({
        paginate: this.options,
        filters: {
          from: this.from,
          to: this.to,
          pay_mode: this.pay_mode,
          recurrente: this.recurrent,

          type: this.filter_type,
          tattooer_id: this.tattooer_id,
          cash_register_id: this.filtersState.cash_register_id,
          ...this.filters,
        },
      }).then((expenses) => {
        this.items = expenses.data;
        this.totalExpenses = expenses.total;
        this.action = "save";
      });
    },
    hideButton() {
      this.$store.state.expenses.hide[this.type] = !this.$store.state.expenses
        .hide[this.type];
      /* if (this.$store.state.expenses.hide[this.type]) {
        //console.log("false", this.$store.state.expenses.hide[this.type]);

        this.type != "confirmed_gastos"
          ? this.type != "confirmed_ingresos"
            ? this.type != "forecast"
              ? this.type == "appointment"
                ? (this.$store.state.appointments.hide = false)
                : null
              : (this.$store.state.expenses.hide.forecast = false)
            : (this.$store.state.expenses.hide.confirmed_ingresos = false)
          : (this.$store.state.expenses.hide.confirmed_gastos = false);
      } else {
        //console.log("true", this.$store.state.expenses.hide[this.type]);
        this.type != "confirmed_gastos"
          ? this.type != "confirmed_ingresos"
            ? this.type != "forecast"
              ? this.type == "appointment"
                ? (this.$store.state.appointments.hide = true)
                : null
              : (this.$store.state.expenses.hide.forecast = true)
            : (this.$store.state.expenses.hide.confirmed_ingresos = true)
          : (this.$store.state.expenses.hide.confirmed_gastos = true);
      }*/
    },
    edit(expense) {
      this.action = "edit";
      Object.keys(expense).forEach((key) => {
        this.expense[key] = expense[key];
      });
      this.expense.gasto = expense.cantidad < 0 ? 1 : 0;

      this.$refs.form.dialog = true;
      this.$refs.form.recharge();
    },
    del(expense) {
      this.$confirm(
        this.$t("delete_expense_info", {
          type: this.title,
        }),
        "",
        ""
      ).then(() => {
        this.delExpense(expense.id).then(() => {
          this.$alert(
            this.$t("delete_expense_confirm", {
              type: this.title,
            }),
            "",
            ""
          );
          this.fetchExpenses();
          this.fetchAmount();
          this.$emit("update");
        });
      });
    },
    openForm() {
      this.$refs.form.open();
    },
    actionSave() {
      this.action = "save";
    },

    fetchAmount() {
      let filters = {
        from: this.from,
        to: this.to,
        pay_mode: this.pay_mode,

        type: this.filter_type,

        tattooer_id: this.tattooer_id,
        cash_register_id: this.filtersState.cash_register_id,

        ...this.filters,
      };
      if (this.type == "appointment") {
        filters.pending = false;
      }
      console.log("fetchAmount", this.type, filters);
      this.getTotalAmount({
        filters,
      }).then((total) => {
        this.total = total;
      });
    },
    getExcel() {
      this.getAll({
        filters: {
          from: this.from,
          to: this.to,
          pay_mode: this.pay_mode,

          type: this.filter_type,

          tattooer_id: this.tattooer_id,
          ...this.filters,
        },
      }).then((data) => {
        //console.log(data);
        this.exportToExcel(
          data,
          this.headers,
          this.$t("expenses.types." + this.type)
        );
      });
    },
    confirm(item) {
      if (item.appointment_date_id)
        this.$confirm(this.$t("liquidate_session")).then(() => {
          this.confirmExpense(item.id).then(() => {
            this.$emit("update");
            this.$alert(this.$t("save_ok"), "", "");
          });
        });
      else
        this.confirmExpense(item.id).then(() => {
          this.$emit("update");
          this.$alert(this.$t("save_ok"), "", "");
        });
    },
    confirmCommission(item) {
      this.$confirm(this.$t("liquidate_commision")).then(() => {
        this.confirmExpenseCommision(item.id).then(() => {
          this.$alert(this.$t("save_ok"), "", "");
          this.$emit("update");
        });
      });
    },
  },
  computed: {
    other_filters_applied() {
      let keys = Object.keys(this.filtersState);

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let filter = this.filtersState[key];
        console.log(key, filter);

        if (
          filter !== "undefined" &&
          filter !== undefined &&
          filter !== "" &&
          filter !== null
        ) {
          switch (key) {
            case "type":
            case "recurrent":
            case "pay_mode":
              return true;
          }
        }
      }
      return false;
    },
    ...mapState("expenses", ["filtersState"]),
    from: {
      get() {
        return this.filtersState.from;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_FROM", value);
      },
    },
    to: {
      get() {
        return this.filtersState.to;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TO", value);
      },
    },
    filter_type: {
      get() {
        return this.filtersState.type;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TYPE", value);
      },
    },
    pay_mode: {
      get() {
        return this.filtersState.pay_mode;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_PAY_MODE", value);
      },
    },
    recurrent: {
      get() {
        return this.filtersState.recurrent;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_RECURRENT", value);
      },
    },

    tattooer_id: {
      get() {
        return this.filtersState.tattooer_id;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TATTOOER_ID", value);
      },
    },
    pay() {
      let p = [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ];
      if (
        this.$store.getters["auth/getSetting"]("pm_stripe_id") !== 0 &&
        this.type !== "confirmed_gastos"
      ) {
        p.push({
          text: this.$t("expenses.online"),
          value: "online",
        });
      }
      return p;
    },
    fromButton() {
      switch (this.type) {
        case "forecast":
        case "dashboard-tattooer":
        case "dashboard-tattooer-small-ingresos-totales":
        case "dashboard-tattooer-small-ingresos-pendientes":
          return false;
        default:
          return true;
      }
    },
    dialogTypeCard() {
      switch (this.type) {
        case "confirmed_gastos":
        case "forecast":
        case "confirmed_ingresos":
        case "dashboard-tattooer-small-ingresos-totales":
        case "dashboard-tattooer-small-ingresos-pendientes":
        case "appointment":
          return true;
        default:
          return false;
      }
    },
    fontsize() {
      let t = this.$n(this.total) + "€";
      console.log(this.type, t, t.length);

      if (t.length > 13) {
        if (this.size === "small") return "1em";
        else if (this.$vuetify.breakpoint.xsOnly) return "30px";
        else return "30px";
      } else if (t.length > 9) {
        if (this.size === "small") return "1.5em";
        else if (this.$vuetify.breakpoint.xsOnly) return "30px";
        else return "40px";
      }
      if (this.size === "small") return "2em";
      else if (this.$vuetify.breakpoint.xsOnly) return "40px";
      else return "50px";
    },
    launchcardelevation() {
      switch (this.size) {
        case "small":
          return "10";
        default:
          return "6px";
      }
    },
    launchcardcolor() {
      switch (this.size) {
        case "small":
          return "";
        default:
          return "grey darken-4";
      }
    },
    tableheight() {
      switch (this.size) {
        case "small":
          return "300px";
        default:
          return "300px";
      }
    },
    fontsizeTitle() {
      if (this.type === "appointment") {
        return "20px";
      }
      switch (this.size) {
        case "small":
          if (this.$vuetify.breakpoint.lgAndDown) {
            return "15px";
          }
          return "20px";
        default:
          return "25px";
      }
    },

    filters() {
      switch (this.type) {
        case "confirmed":
          return { pending: false, recurrente: false };
        case "non-confirmed":
          return { pending: true, recurrente: false };
        case "recurrente":
          return { recurrente: true };
        case "appointment":
          return { appointment_id: this.appointment.id };
        case "confirmed_gastos":
          return { pending: false, gasto: true };
        case "confirmed_ingresos":
          return { pending: false, gasto: false };
        case "dashboard-tattooer-small-ingresos-totales":
          return { gasto: false };
        case "dashboard-tattooer-small-ingresos-pendientes":
          return { pending: true, gasto: false };
        case "forecast":
          return { pending: true };
        case "customer":
          return { customer_id: this.customer.id || -1 };

        case "tattooer":
          if (this.comision)
            return { tattooer_id: this.tattooer.id || -1, is_comision: true };
          else return { tattooer_id: this.tattooer.id || -1 };
        default:
          return {};
      }
    },
    headers() {
      switch (this.type) {
        case "appointment":
        case "customer":
          return [
            {
              text: this.$tc("expenses.concepto", 2),
              align: "center",
              sortable: false,
              value: "conceptos",
            },
            {
              text: this.$t("expenses.data"),
              align: "center",
              sortable: false,
              value: "data",
            },
            {
              text: this.$t("expenses.state"),
              align: "center",
              sortable: false,
              value: "pending",
            },
            {
              text: this.$t("expenses.pay_mode"),
              align: "center",
              sortable: false,
              value: "pay_mode",
            },
            {
              text: this.$t("expenses.cantidad"),
              align: "center",
              sortable: false,
              value: "cantidad",
            },
            {
              text: this.$t("actions"),
              align: "center",
              sortable: false,
              value: "actions",
            },
          ];

        default:
          let h = [
            {
              text: this.$tc("expenses.concepto", 2),
              align: "center",
              sortable: false,
              value: "conceptos",
            },
            {
              text: this.$t("expenses.data"),
              align: "center",
              sortable: false,
              value: "data",
            },
            {
              text: this.$t("expenses.cantidad"),
              align: "center",
              sortable: false,
              value: "cantidad",
            },
          ];
          if (this.$store.getters["auth/isStudio"])
            h.push({
              text: this.$tc("artist"),
              align: "center",
              sortable: false,
              value: "tattooer",
            });
          h.push({
            text: this.$t("expenses.pay_mode"),
            align: "center",
            sortable: false,
            value: "pay_mode",
          });

          h.push({
            text: this.$t("actions"),
            align: "center",
            sortable: false,
            value: "actions",
          });

          return h;
      }
    },
  },
};
</script>
<style lang="sass">
#tableExpense
  td:first-child
    //max-width: 350px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
#tableLauncher

.lessMargin
  height: 30px
  cursor: pointer
  .v-list-item__icon
    margin-right: 5px !important
  .v-icon, .svg-icon
    margin-top: -3px
    color: var(--v-gris1-lighten2) !important
#card
  .appoinment_penging
    font-weight: bolder
#others_expenses
  overflow: hidden
  .no-line
    &.v-text-field > .v-input__control > .v-input__slot:after,
    &.v-text-field > .v-input__control > .v-input__slot:before
      visibility: hidden
  .v-list-item
    height: 30px
    margin-top: 5px
    color: var(--v-gris1-lighten2)
  .filters .svg-icon, .filters .v-icon
    fill: var(--v-primary-base)
    color: var(--v-primary-base)
    height: 20px
  .filters .v-label
    color: white
@media (min-width: 400px) and ( max-width: 980px)
  #tableExpense
    td:first-child
      max-width: 230px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

@media (max-width: 400px)
  #title
    height: 50px !important
  .v-card__title
    padding-top: 0px
</style>
<style scoped lang="sass">
label
  line-height: 1
</style>
